// ========================================
//  aem scss templates
//      ver 2.0.4
// ======================================== 
@charset 'UTF-8';


/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
@import "common/variables";
@import "common/mixins";
@import "common/reset";
@import "common/base";
@import "common/parts";



/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
html {
    height: 100%;
}

body {
    min-height: 100%;
    background: #000 url("../images/common/space.jpg");
    background-attachment: fixed;
}

.wrapper {
    max-width: 880px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
}

.content {
    position: relative;
    display: flex;
}

.row {
    display: flex;
    align-items: flex-end;
}

.content-inner {
    margin-left: 320px;
}

.site-image {
    position: fixed;
    width: 300px;
    min-width: 300px;
    height: 621px;
    overflow: auto;
    margin-right: 40px;

    .frame {
        width: 300px;
    }

    &__inner {
        position: absolute;
        top: 33px;
        left: 12px;
        width: 92%;
        height: 576px;
        overflow: hidden;
        z-index: 10;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}

@keyframes site {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-1500px);
    }
}


.title {
    @include eng-font();
    font-weight: 700;
    font-size: 4rem;
}

.list-title {
    @include eng-font();
    font-weight: 700;
    font-size: 1.5rem;
}

.list {
    // display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;

    &__item {
        background-color: #f3f3f3;
        padding: 10px;
        margin-bottom: 10px;

        &-title {
            font-weight: bold;
            margin-bottom: 5px;
        }

        &-body {
            font-size: 1.3rem;
            line-height: 1.6em;
        }
    }
}

.container {
    padding-top: 1.75rem;
    margin-top: 1.75rem;
    border-top: 1px solid #ddd;
}

.vip-menu {
    padding-bottom: 1.75rem;
}